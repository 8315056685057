import React from 'react'
import tw from 'twin.macro'
import LinkButton from '@elements/LinkButton'
import Stripes from '@elements/Stripes'
import EmfImage from '@elements/EmfImage'

const Section = tw.section`relative flex justify-end`
const Wrap = tw.div`relative ml-5 md:ml-0 md:w-10/12 mt-[440px] xl:mt-[600px] xl:max-w-screen-2xl`
const Headline = tw.h2`absolute bottom-full mb-10 max-w-[340px] md:max-w-[800px] md:left-1/3  z-20 md:w-2/3 md:pr-10`
const Text = tw.p`mt-6 xs:mt-0 relative text-black xs:text-white xs:absolute text-10px xs:bottom-5 left-5 md:bottom-10 md:left-14 w-1/2 md:w-1/3 xl:w-1/5 italic md:text-xs xl:text-sm tracking-1 md:tracking-2`
const ImageWrap = tw.div`relative w-full h-auto ml-auto xl:h-[85vh]`

const StyledLinkButton = tw(LinkButton)`mt-6 xs:mt-11`
const StyledStripes = tw(Stripes)`top-0 left-0`

const WpAcfTeaserticketBlock = ({ data }) => {
	const { anchor } = data.attributes
	const module = data.modulesTeaserTicket

	return (
		<Section {...(anchor !== '' ? { id: anchor } : {})} className='emf-pb-spacing'>
			<StyledStripes layout='rotated' />
			<Wrap>
				{module.headline && <Headline className='headline-h2'>{module.headline}</Headline>}
				<ImageWrap>
					<EmfImage
						className='w-full h-[455px] sm:h-[455px] md:h-[520px] md:min-h-[455px] lg:h-auto rounded-emf xl:h-[85vh]'
						altText={module.image?.altText}
						src={module.image?.localFile}
						speed={0.03}
						moveDown={false}
					/>
					{module.text && <Text>{module.text}</Text>}
				</ImageWrap>
				{module.button?.title && <StyledLinkButton label={module.button.title} href={module.button.url} />}
			</Wrap>
		</Section>
	)
}

export default WpAcfTeaserticketBlock
